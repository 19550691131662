import React, {useState, useRef, useEffect } from 'react'
import IconPhone from '../atoms/icons/IconPhone'
import IconMessage from '../atoms/icons/IconMessage'
import IconPrint from '../atoms/icons/IconPrint'
import axios from 'axios'

const Footer = () => {

    const urlSMS = 'https://dev-payment.wepay4u.com/api/v1/gateway/SendPaymentInstructionSMS'
    const urlEmail = 'https://dev-payment.wepay4u.com/api/v1/gateway/SendPaymentInstructionEmail'
    const API_KEY = document.getElementById('wepay4u').getAttribute('data-apikey')
    const [value, setValue] = useState('')
    const [response, setResponse] = useState(0)
    const [status, setStatus] = useState(false)
    const [tab, setTab] = useState('1')

    const formDataSMS = new FormData()
    const formDataEmail = new FormData()

    function handleChange(event){
        setValue(event.target.value)
    }

    formDataSMS.append('token',API_KEY);
    formDataSMS.append('phonnumber',value);

    formDataEmail.append('token',API_KEY);
    formDataEmail.append('emailShopper',value);

    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }

    const myInputEmail = useRef()
    useEffect(() => myInputEmail.current && myInputEmail.current.focus())

    function sendInfo(event){
        event.preventDefault()
        if(document.getElementById('phonnumber')){
            document.getElementById('phonnumber').value = ''
        }

        if(document.getElementById('emailShopper')) {
            document.getElementById('emailShopper').value = ''
        }


        if( tab === '1' ){
            axios.post(urlSMS, formDataSMS, config)
            .then(response => {
                setStatus(true)
                setResponse(1)
                console.log(response);
            })
            .catch(error => {
                setStatus(true)
                setResponse(1)
                console.log(error);
            });
        }
        if( tab === '2'){
            //if (emailRegex({exact: true}).test()) {
                axios.post(urlEmail, formDataEmail, config)
                .then(response => {
                    setStatus(true)
                    setResponse(1)
                    console.log(response);
                })
                .catch(error => {
                    setStatus(true)
                    setResponse(1)
                    console.log(error);
                });
            //}else{
            //    console.log("no es valido")
            //}
        }
    }

    function toPrint(){
        console.log("imprimir")
        window.print()
    }

    return (
        <footer>
            <div className="w-footer">
                <div className="w-footer-l">
                    <p>Compartir por:</p>
                    <ul className="shared-list">
                        <li>
                            <button onClick={() => setTab('1')}  className={tab === '1' ?  `${tab} active` : `${tab}`} >
                                <IconPhone strokeColor={'#FFFFFF'} />
                            </button>
                        </li>
                        <li>
                            <button onClick={() => setTab('2')} className={tab === '1' ?  `${tab} ` : `${tab} active`} >
                                <IconMessage strokeColor={'#FFFFFF'} />
                            </button>
                        </li>
                        <li>
                            <button onClick={() => toPrint()}>
                                <IconPrint strokeColor={'#FFFFFF'} />
                            </button>
                        </li>
                    </ul>
                    <form id="frmSend">
                        {
                            (tab === '1') ?
                            <input id="phonnumber" name="phonnumber" type="text" placeholder="Número de celular" onChange={handleChange} />
                            :
                            <input id="emailShopper" name="emailShopper" type="email" ref={myInputEmail} placeholder="Correo electrónico" onChange={handleChange} />
                        }

                        <button type="submit" onClick={sendInfo}>Enviar</button>
                    </form>
                    {(response === 1) ? ( (status === true) ? <p className="success">Enviado satisfactoriamente</p> : <p className="error">Hubo un problema al enviarlo. Inténtelo más tarde</p> ) : ''}
                </div>
                <div className="w-footer-r">
                    <div className="queries">
                        <p>Consultas y ayuda</p>
                        <a href="mailto:contactos@wepay4u.com" className="email">contactos@wepay4u.com</a>
                    </div>
                    <div className="w-typ">
                        <p><a href=""><b>Términos y Condiciones</b> </a> y <a href=""><b>Políticas de privacidad.</b></a> </p>
                    </div>
                </div>
            </div>
        </footer>

    )
}

export default Footer
