import React, {useState} from 'react'
import { connect } from 'react-redux'
import { updateStatus } from '../../redux/actionsCreators'
import axios from 'axios'
//import Icon from '../molecules/Icon'

const ListBanksOnline = ({ db, getStatus }) => {
    const listBanksOnline = db.data.channel.Online.listBanks

    const urlOperacion = 'https://dev-payment.wepay4u.com/api/v1/gateway/SendBankOperation'
    const API_KEY = document.getElementById('wepay4u').getAttribute('data-apikey')
    const [response, setResponse] = useState(null)
    const [message, setMessage] = useState('')
    const [disInput, setdisInput] = useState(0)

    const [slide, setSlide] = useState( listBanksOnline && listBanksOnline.length > 0 ? 0 : undefined )
    const [color, setColor] = useState (listBanksOnline[0].Color)
    const [shortName, setShortName] = useState(listBanksOnline[0].ShortName)
    const [urlBank, setUrlBank] = useState(listBanksOnline[0].UrlBank)
    const [version, setVersion] = useState(listBanksOnline[0].Version)
    const [instructions, setInstructions] = useState(listBanksOnline[0].Instructions)
    const [loader, setLoader] = useState('desactive')
    const [bankCode, setBankCode] = useState(listBanksOnline[0].BankCode)
    const [operacion] = useState({token:'', bank_code:0, bank_operation: ''})

    function sendOperacion(event){
      operacion.token = API_KEY
      operacion.bank_code = bankCode
      operacion.bank_operation = document.getElementById('inputCodigo').value

      console.log(operacion.bank_operation+" - "+operacion.bank_code+"  "+operacion.token)

      axios.post(urlOperacion, operacion)
            .then(response => {
              setResponse(response.data.status);
              setMessage(response.data.message);
              setdisInput(1)
            })

      }


    function goLeft(params){
        setSlide( params ? params - 1 : listBanksOnline.length - 1)
        for( let i = 0; i < listBanksOnline.length; i++ ){
            if( params === i && i !== -1){
                const newIndex = i - 1
                if(newIndex !== -1){
                    setColor(listBanksOnline[newIndex].Color)
                    setShortName(listBanksOnline[newIndex].ShortName)
                    setUrlBank(listBanksOnline[newIndex].UrlBank)
                    setVersion(listBanksOnline[newIndex].Version)
                    setInstructions(listBanksOnline[newIndex].Instructions)
                    setBankCode(listBanksOnline[newIndex].BankCode)
                    setResponse(null)
                    setMessage('')
                }else{
                    setColor(listBanksOnline[listBanksOnline.length - 1].Color)
                    setShortName(listBanksOnline[listBanksOnline.length - 1].ShortName)
                    setUrlBank(listBanksOnline[listBanksOnline.length - 1].UrlBank)
                    setVersion(listBanksOnline[listBanksOnline.length - 1].Version)
                    setInstructions(listBanksOnline[listBanksOnline.length - 1].Instructions)
                    setBankCode(listBanksOnline[listBanksOnline.length - 1].BankCode)
                    setResponse(null)
                    setMessage('')
                }
            }
        }
    }

    function goRight(params){
        setSlide((slide + 1) % listBanksOnline.length)
        for( let i = 0; i < listBanksOnline.length; i++ ){
            if( params === i && i !== listBanksOnline.length ){
                const newIndex = i + 1 % listBanksOnline.length
                if(newIndex !== listBanksOnline.length){
                    setColor(listBanksOnline[newIndex].Color)
                    setShortName(listBanksOnline[newIndex].ShortName)
                    setUrlBank(listBanksOnline[newIndex].UrlBank)
                    setVersion(listBanksOnline[newIndex].Version)
                    setInstructions(listBanksOnline[newIndex].Instructions)
                    setBankCode(listBanksOnline[newIndex].BankCode)
                    setResponse(null)
                    setMessage('')
                }else{
                    setColor(listBanksOnline[0].Color)
                    setShortName(listBanksOnline[0].ShortName)
                    setUrlBank(listBanksOnline[0].UrlBank)
                    setVersion(listBanksOnline[0].Version)
                    setInstructions(listBanksOnline[0].Instructions)
                    setBankCode(listBanksOnline[0].BankCode)
                    setResponse(null)
                    setMessage('')
                }
            }
        }
    }

    const getBank = params => () =>  {
        setSlide(params)
        for( let i = 0; i < listBanksOnline.length; i++ ){
            if( params === i){
                setColor(listBanksOnline[i].Color)
                setShortName(listBanksOnline[i].ShortName)
                setUrlBank(listBanksOnline[i].UrlBank)
                setInstructions(listBanksOnline[i].Instructions)
                setVersion(listBanksOnline[i].Version)
                setBankCode(listBanksOnline[i].BankCode)
                setResponse(null)
                setMessage('')

            }
        }
    }
    /*
    function scrollTop(){
        window.scrollTo(0, 0)
    }
    */

    return(
        <>
            <div  className={loader === 'active' ?  `c-loading active` : `c-loading`} >
                <div className="progress-content__box">
                    <div data-status="loading" className="spinner-loader">
                    <ul role="progressbar" aria-busy="true" aria-label="Loading domino shop">
                        <li role="presentation"></li>
                        <li role="presentation"></li>
                        <li role="presentation"></li>
                        <li role="presentation"></li>
                        <li role="presentation"></li>
                        <li role="presentation"></li>
                        <li role="presentation"></li>
                    </ul>
                    </div>
                    <p>Esperando confirmación de pago...</p>
                </div>
            </div>
            <div className="wrap-slider">
                <ul className={`slider s-`+slide} >
                    { listBanksOnline.map( (item, index) => (
                        <li key={item.BankID}
                            className={index === slide ?  `slide-item ${slide} active` : `slide-item ${index}`}>
                            <button onClick={ getBank(index) }  >
                                <img className="img-bank" src={item.UrlLogo} alt={item.ShortName} />
                            </button>
                        </li>
                    ))}
                </ul>
                <button className="btn-arrow-left" data={slide} onClick={() => goLeft(slide)}>atrás</button>
                <button className="btn-arrow-right" data={slide} onClick={() => goRight(slide)}>adelante</button>
            </div>

            {(version==='1.0') && (
            <div>
              <p className="subtitle">Instrucciones de pago {shortName} </p>
              <h3 className="type-pay">Pago en línea</h3>
              <ul className="list-steps-1">
                  {instructions.map( item => (
                      <li key={item.step}>
                          {/*{item.icon ?  <img src={item.icon} alt={item.step} /> :  <Icon strokeColor={color}  typeIcon = {1}/> */}
                          {item.icon ?  <img src={item.icon} alt={item.step} />  : '' }
                          <p>
                              <strong style={{ color: color }}>Paso {item.step}: </strong>
                              <span dangerouslySetInnerHTML={ {__html: item.text} }></span>
                          </p>
                      </li>
                  ))}
              </ul>
            </div>
            )}

            {(version==='0.1') && (
              <div>
              {(instructions.length>3)&&(
                <div>
                  <ul className="list-steps-0">
                      <li key={instructions[0].step}>
                          <p><strong style={{ color: color}}>Paso {instructions[0].step}: </strong></p>
                          {instructions[0].icon ?  <img style={{marginBottom: '20px'}} src={instructions[0].icon} alt={instructions[0].step} />  : '' }
                          <div className="separador"></div>
                          <p><span dangerouslySetInnerHTML={ {__html: instructions[0].text} }></span></p>
                          <p style={{textAlign: 'justify'}}>Numero de cuenta:   <strong>{instructions[0].acount}</strong></p>
                          <p style={{textAlign: 'justify'}}>Cuenta interbancaria:   <strong>{instructions[0].cci}</strong></p>
                      </li>
                        <li key={instructions[1].step}>
                          <p><strong style={{ color: color}}>Paso {instructions[1].step}: </strong></p>
                          {instructions[1].icon ?  <img src={instructions[1].icon} alt={instructions[1].step} />  : '' }
                          <div className="separador"></div>
                          <p><span dangerouslySetInnerHTML={ {__html: instructions[1].text} }></span></p>
                        </li>
                        <li key={instructions[2].step}>
                            <p><strong style={{ color: color}}>Paso {instructions[2].step}: </strong></p>
                            {instructions[2].icon ?  <img style={{marginBottom: '20px'}} src={instructions[2].icon} alt={instructions[2].step} />  : '' }
                            <div className="separador"></div>
                            <p><span dangerouslySetInnerHTML={ {__html: instructions[2].text} }></span></p>
                            <form>
                              {(!disInput) ? (
                                <div>
                                <input id="inputCodigo" name="number" type="text" placeholder="Numero de operación"/>
                                <button type="button" onClick={sendOperacion}>Enviar</button>
                                </div>
                              ) :
                              <div>
                              <input disabled id="inputCodigo" name="number" type="text" placeholder="Numero de operación"/>
                              <button disabled type="button" onClick={sendOperacion}>Enviar</button>
                              </div>
                            }
                            </form>
                            {(response!=null) ? ((response===true) ? ( <p className="success">{message}</p>) : (<p className="error">Hubo un problema al enviarlo. Inténtelo más tarde</p> )) : ''}
                            <p> Envianos tu vaucher a nuestro email:</p>
                            <p><strong>pagos@wepay4u.com</strong></p>
                        </li>
                  </ul>
                  <ul className="list-steps-0">
                      <li key={instructions[3].step} style={{ backgroundColor: 'white', borderRadius:30, padding:'3%' }}>
                            <div style={{marginLeft: 'auto', marginRight: 'auto', width:'33%', float: 'left'}}>
                              {instructions[3].icon ?  <img style={{width:'94px'}} src={instructions[3].icon} alt={instructions[3].step} />  : '' }
                            </div>
                            <div style={{marginLeft: 'auto',marginRight: 'auto', width:'33%', float: 'left'}}>
                              <p style={{textAlign: 'center', padding: 'inherit', }}><strong style={{ color: color }}> Recuerda </strong></p>
                              <p style={{textAlign: 'center'}}><span dangerouslySetInnerHTML={ {__html: instructions[3].text} }></span></p>
                            </div>
                            <div style={{marginLeft: 'auto',marginRight: 'auto', width:'33%', float: 'left', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                              <p style={{marginTop: '5%'}}><strong>pagos@wepay4u.com</strong></p>
                            </div>
                      </li>
                  </ul>
                </div>
                )}
                {(instructions.length<=3)&&(
                  <div>
                    <ul className="list-steps-0">
                        <li key={instructions[0].step}>
                            <p><strong style={{ color: color}}>Paso {instructions[0].step}: </strong></p>
                            {instructions[0].icon ?  <img style={{marginBottom: '20px'}} src={instructions[0].icon} alt={instructions[0].step} />  : '' }
                            <div className="separador"></div>
                            <p><span dangerouslySetInnerHTML={ {__html: instructions[0].text} }></span></p>
                            <p style={{textAlign: 'justify'}}>Numero de cuenta:   <strong>{instructions[0].acount}</strong></p>
                            <p style={{textAlign: 'justify'}}>Cuenta interbancaria:   <strong>{instructions[0].cci}</strong></p>
                        </li>
                        <li key={instructions[1].step}>
                            <p><strong style={{ color: color}}>Paso {instructions[1].step}: </strong></p>
                            {instructions[1].icon ?  <img style={{marginBottom: '20px'}} src={instructions[1].icon} alt={instructions[1].step} />  : '' }
                            <div className="separador"></div>
                            <p><span dangerouslySetInnerHTML={ {__html: instructions[1].text} }></span></p>
                            <form>
                              {(!disInput) ? (
                                <div>
                                <input id="inputCodigo" name="number" type="text" placeholder="Numero de operación"/>
                                <button type="button" onClick={sendOperacion}>Enviar</button>
                                </div>
                              ) :
                              <div>
                              <input disabled id="inputCodigo" name="number" type="text" placeholder="Numero de operación"/>
                              <button disabled type="button" onClick={sendOperacion}>Enviar</button>
                              </div>
                            }
                            </form>
                            {(response!=null) ? ((response===true) ? ( <p className="success">{message}</p>) : (<p className="error">Hubo un problema al enviarlo. Inténtelo más tarde</p> )) : ''}
                            <p> Envianos tu vaucher a nuestro email:</p>
                            <p><strong>pagos@wepay4u.com</strong></p>
                        </li>
                    </ul>
                    <ul className="list-steps-0">
                        <li key={instructions[2].step} style={{ backgroundColor: 'white', borderRadius:30, padding:'3%' }}>
                              <div style={{marginLeft: 'auto', marginRight: 'auto', width:'33%', float: 'left'}}>
                                {instructions[2].icon ?  <img style={{width:'94px'}} src={instructions[2].icon} alt={instructions[2].step} />  : '' }
                              </div>
                              <div style={{marginLeft: 'auto',marginRight: 'auto', width:'33%', float: 'left'}}>
                                <p style={{textAlign: 'center', padding: 'inherit', }}><strong style={{ color: color }}> Recuerda </strong></p>
                                <p style={{textAlign: 'center'}}><span dangerouslySetInnerHTML={ {__html: instructions[2].text} }></span></p>
                              </div>
                              <div style={{marginLeft: 'auto',marginRight: 'auto', width:'33%', float: 'left', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <p style={{marginTop: '5%'}}><strong>pagos@wepay4u.com</strong></p>
                              </div>
                        </li>
                    </ul>
                  </div>
                  )}

            </div>
            )}
            
            <div className="info">
              <a style={{ color: color }} href={urlBank} target="_blank" rel="noopener noreferrer" className="btn" >   Ir al banco   </a>
              {/*                  
              <div>
                  
                  <a style={{ color: color }}
                  href={urlBank} target="_blank" rel="noopener noreferrer"
                  onClick={() => {getStatus(1); setLoader('active') } } className="btn" > Ir al banco
                  </a>

              </div>
              
              <div>
                  <a style={{ color: color }}
                  href={urlBank} target="_blank" rel="noopener noreferrer"
                  onClick={() => {getStatus(1); setLoader('false') } } className="btn" > Ya realice el deposito
                  </a>
              </div>
              */}          
              <p><b>Horario de atención de {shortName}</b> - de Lunes a viernes de 9 am. a 6 pm. - Sábado de 9 am. a 3 pm.</p>
              
            </div>
        </>
    )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
    getStatus(newStatus){
        dispatch(updateStatus(newStatus))
        console.log(newStatus)
    }
})




export default connect(mapStateToProps, mapDispatchToProps)(ListBanksOnline)
