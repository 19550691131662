import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TransferForm from '../molecules/TransferForm'
import ReactHtmlParser from 'react-html-parser';
import { BiPrinter } from 'react-icons/bi';
import { BsChatSquareDots } from 'react-icons/bs';
import { GoMail } from 'react-icons/go';
import axios from 'axios'


import GreenRadio from '../atoms/GreenRadio'
import BankCardComponent from '../atoms/BankCard'
import dayjs from 'dayjs'
import Modal from 'react-modal';
import { IoMdClose } from 'react-icons/all'
import {extendedFieldBankList} from "../../utils/const";
import ModalResponse from "./ModalResponse";

const accountNormalize = (type) => {
  switch (type) {
    case 'business':
      return 'Conta Jurídica'
    case 'saving':
      return 'Conta Poupança'
    case 'checking':
    default:
      return 'Conta Corrente'
  }
}

const TransferView = ({ setStep, db }) => {
  const urlSMS = 'https://dev-payment.wepay4u.com/api/v1/gateway/SendPaymentInstructionSMS'
  const urlEmail = 'https://dev-payment.wepay4u.com/api/v1/gateway/SendPaymentInstructionEmail'
  const [selectedBank, setSelectedBank] = useState(db.data.channel.TransferOnline.banks.find((transfer) => transfer.BankIndex === 1).ShortName)
  const [selectedBankData, setSelectedBankData] = useState({ Instructions: [] })
  const [payerData, setPayerData] = useState(1)
  const [transferStep, setTransferStep] = useState(0)
  const [editing, setEditing] = useState(false)
  const [isOpenMobileModal, setIsOpenMobileModal] = useState(false)
  const [banks, setBanks] = useState([])
  const [modalMessage, setModalMessage] = useState('')
  const [isOpenModal, setIsOpenModal] = useState(false)
  const shopper = db.data.channel.TransferOnline.shopper
  const API_KEY = document.getElementById('wepay4u').getAttribute('data-apikey')

  const formDataSMS = new FormData()
  const formDataEmail = new FormData()
  const config = {
    headers: { 'content-type': 'multipart/form-data' }
  }

  useEffect(() => {
    setBanks(
      db.data.channel.TransferOnline.banks?.map((transfer) => transfer.ShortName)
    )
  }, [db.data.channel.TransferOnline]);

  useEffect(() => {
    if (selectedBank) setSelectedBankData(
      db.data.channel.TransferOnline.banks.find((transfer) => transfer.ShortName === selectedBank)
    )
  }, [selectedBank, db.data.channel.TransferOnline])


  const [data, setData] = useState({})

  const { register, handleSubmit, formState: { errors, isValid }, control, clearErrors, setValue, reset } = useForm({
    mode: 'all'
  });

  const stepOneSubmit = formData => {
    sendFormData(formData)
    setData(formData)
    setTransferStep(1)
  };

  const stepOneSubmitMobile = formData => {
    sendFormData(formData)
    setData(formData)
    setIsOpenMobileModal(true)
  };

  const stepTwoSubmit = formData => {
    sendFormData(formData)
    setData(formData)
    setEditing(false)
  };

  const changeBank = (bank) => {
    clearErrors()
    setSelectedBank(bank)
  }

  const changePayer = (value) => {
    if(value.target.value){
      clearErrors()
      reset({Mobile:""})
    }
    setPayerData(parseInt(value.target.value))
  }

  const getNumberAccount = (bankName) => {
    const accounts = {
      "BRADESCO": {
        Ag: '1998',
        Cuenta: '0025728-1'
      },
      ["ITAÚ"]: {
        Ag: '0734',
        Cuenta: '99801-6'
      },
      "DOBRASIL": {
        Ag: '3050-3',
        Cuenta: '24787-1'
      },
      "SANTANDER": {
        Ag: '4263',
        Cuenta: '13004848-1'
      },
    }
    return accounts[bankName]
  }

  const sendFormData = (formData) => {
    const url = 'https://dev-payment.wepay4u.com/api/v1/payment_order/store_customer_payment'
    const data = {
      PublicID: db.data.dataOperation.PublicId,
      SameShopper: Boolean(payerData),
      MetaData: {
        NumberAgence: formData.branch,
        NumberAccount: formData.account,
        TypeAccount: formData.accountType
      },
      Customer:{
        Email: formData.Email,
        FirstName: formData.FirstName,
        LastName: formData.LastName,
        DocNumber: formData.DocNumber,
        DocType: db.data.channel.TransferOnline.shopper.DocType,
        Phone: formData.Mobile.replace(/[^\d]/g, ''),
        Mobile: formData.Mobile.replace(/[^\d]/g, ''),
        CountryCode: db.data.dataOperation.CountryCode
      }
    }
      axios.post(url, data, {
        auth: {
          username: '21813e90775cd703b8327b189aabad80b3f73633',
          password: ''
        },
      }).then(response => {
        console.log(response)
      }).catch(err => {
        setIsOpenModal(true)
        setModalMessage(err.response.data.Message)
      })
  }

  const sendSMS = () => {
    formDataSMS.append('token',API_KEY);
    formDataSMS.append('phonnumber', shopper.Mobile);

    axios.post(urlSMS, formDataSMS, config)
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
  }

  const sendEmail = () => {
    formDataEmail.append('token',API_KEY);
    formDataEmail.append('emailShopper', shopper.Email);

    axios.post(urlEmail, formDataEmail, config)
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
  }

  const printDiv = () => {
    let head = document.head || document.getElementsByTagName('head')[0]
    let style = document.createElement('style');

    head.appendChild(style);
    let css = `
    @media print {
        body * {
            visibility: hidden;
        }
            #wepay4u {
                overflow: initial !important;
            }
        #printAreaBRA, #printAreaBRA * {
            visibility: visible;
        }
        #printAreaBRA {
            position: absolute;
            top: 50px;
            left: 50px;
        }
    }
    `
    style.appendChild(document.createTextNode(css));
    window.print();
  }

  return (
    <>
      {transferStep === 0 && (
        <Step0.Container>
          <div>
            <Step0.Title>
              Instruções para pagamento
            </Step0.Title>
            <Step0.BanksContainer>
              {banks.map((bank) => (
                <BankCardComponent key={bank} db={db} bank={bank} active={bank === selectedBank} changeFunc={changeBank} />
              ))}
            </Step0.BanksContainer>
            <div>
              <Step0.Hint>
                *Se o seu banco não aparecer na lista acima, lembre-se que você também poderá
                realizar o pagamento via <span onClick={() => setStep('pix')}>PIX</span>
              </Step0.Hint>
              {selectedBank && (
                <>
                  <Step0.HR />
                  <Step0.PayerDataContainer>
                    <Step0.PayerDataText>Os dados abaixo são os dados do pagador?</Step0.PayerDataText>
                    <RadioGroup
                      aria-label="paymentData"
                      name="paymentData"
                      row
                      value={String(payerData)}
                      defaultValue="1"
                      onChange={(e) => changePayer(e)}
                    >
                      <FormControlLabel control={<GreenRadio size="small" />} label="Sim" value="1" />
                      <FormControlLabel control={<GreenRadio size="small" />} label="Não" value="0" />
                    </RadioGroup>
                  </Step0.PayerDataContainer>
                  <div>
                    <TransferForm
                      register={register}
                      handleSubmit={handleSubmit}
                      errors={errors}
                      isValid={isValid}
                      control={control}
                      setValue={setValue}
                      onSubmit={stepOneSubmit}
                      onSubmitMobile={stepOneSubmitMobile}
                      selectedBank={selectedBank}
                      payerData={payerData}
                      data={payerData ? shopper : {}}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <Modal
            isOpen={isOpenMobileModal}
            onRequestClose={() => setIsOpenMobileModal(false)}
            style={{
              overlay: {
                backgroundColor: 'rgba(132, 132, 132, .4)',
              },
              content: {
                margin: 0,
                padding: 0,
                bottom: '4%',
                left: 0,
                right: 0,
                top: '10%',
                borderRadius: '15px 15px 0px 0px',
              }
            }}
          >
            <ModalMobile.Container>
              <ModalMobile.HeaderContainer>
                <ModalMobile.HeaderTextContainer>
                  <ModalMobile.HeaderTextContainerSubtitle>
                    Você tem até
                  </ModalMobile.HeaderTextContainerSubtitle>
                  <ModalMobile.HeaderTextContainerTitle>
                    {dayjs(db.data.dataOperation.TxExpiration).format('DD/MM/YYYY')} <ModalMobile.HeaderTextContainerTitleHint>{dayjs(db.data.dataOperation.TxExpiration).format('HH:mm:ss') + ' Hrs'}</ModalMobile.HeaderTextContainerTitleHint>
                  </ModalMobile.HeaderTextContainerTitle>
                  <ModalMobile.HeaderTextContainerSubtitle2>
                    para realizar o seu pagamento
                  </ModalMobile.HeaderTextContainerSubtitle2>
                </ModalMobile.HeaderTextContainer>
                <ModalMobile.HeaderCloseContainer onClick={() => setIsOpenMobileModal(false)}>
                  <IoMdClose size={20}/>
                </ModalMobile.HeaderCloseContainer>
              </ModalMobile.HeaderContainer>
              <ModalMobile.InfoAccountDetailsContainer>
                <ModalMobile.InfoDetailsLabelContainer border={true}>
                  <ModalMobile.InfoDetailsLabel>
                    Agência
                  </ModalMobile.InfoDetailsLabel>
                </ModalMobile.InfoDetailsLabelContainer>
                <ModalMobile.InfoDetailsTextContainer border={true}>
                  <ModalMobile.InfoDetailsText>
                    {selectedBankData.BranchNumber}
                  </ModalMobile.InfoDetailsText>
                </ModalMobile.InfoDetailsTextContainer>
                <ModalMobile.InfoDetailsLabelContainer border={true}>
                  <ModalMobile.InfoDetailsLabel>
                    Conta corrente
                  </ModalMobile.InfoDetailsLabel>
                </ModalMobile.InfoDetailsLabelContainer>
                <ModalMobile.InfoDetailsTextContainer border={true}>
                  <ModalMobile.InfoDetailsText>
                    {selectedBankData.AccountNumber}
                  </ModalMobile.InfoDetailsText>
                </ModalMobile.InfoDetailsTextContainer>
                <ModalMobile.InfoDetailsLabelContainer border={true}>
                  <ModalMobile.InfoDetailsLabel>
                    Valor Total
                  </ModalMobile.InfoDetailsLabel>
                </ModalMobile.InfoDetailsLabelContainer>
                <ModalMobile.InfoDetailsTextContainer border={true}>
                  <ModalMobile.InfoDetailsText>
                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(db.data.dataOperation.Amount))}
                  </ModalMobile.InfoDetailsText>
                </ModalMobile.InfoDetailsTextContainer>
                <ModalMobile.InfoDetailsLabelContainer>
                  <ModalMobile.InfoDetailsLabel>
                    Número do pedido
                  </ModalMobile.InfoDetailsLabel>
                </ModalMobile.InfoDetailsLabelContainer>
                <ModalMobile.InfoDetailsTextContainer>
                  <ModalMobile.InfoDetailsText>
                    {db.data.dataOperation.PaymentCode}
                  </ModalMobile.InfoDetailsText>
                </ModalMobile.InfoDetailsTextContainer>
              </ModalMobile.InfoAccountDetailsContainer>
              <ModalMobile.InfoDetailsInstructionsTitleContainer>
                <ModalMobile.InfoDetailsInstructionsTitle>Faça a transferência seguindo o passo a passo a seguir:</ModalMobile.InfoDetailsInstructionsTitle>
              </ModalMobile.InfoDetailsInstructionsTitleContainer>
              {selectedBankData.Instructions.map((instruction) => (
                <ModalMobile.InfoDetailsInstructionsContainer key={instruction.step}>
                  {instruction.icon
                      ? <img src={instruction.icon} style={{ alignSelf: 'flex-start', width: 16 }} alt={`Instrução ${instruction.step}`} />
                      : <span>&#9900;</span>
                  }
                  <ModalMobile.InfoDetailsInstructionsText>{ReactHtmlParser(instruction.text)}</ModalMobile.InfoDetailsInstructionsText>
                </ModalMobile.InfoDetailsInstructionsContainer>
              ))}
              <ModalMobile.InfoDetailsFooterContainer>
                <ModalMobile.InfoDetailsFooterButtonContainer><ModalMobile.InfoDetailsFooterButton onClick={() => window.open(selectedBankData.UrlBank, '_blank').focus()}>Ir a banco</ModalMobile.InfoDetailsFooterButton></ModalMobile.InfoDetailsFooterButtonContainer>
                <ModalMobile.InfoDetailsFooterHelpersContainer><ModalMobile.InfoDetailsFooterHelper><BsChatSquareDots size={16} /></ModalMobile.InfoDetailsFooterHelper><ModalMobile.InfoDetailsFooterHelper><GoMail size={16} /></ModalMobile.InfoDetailsFooterHelper><ModalMobile.InfoDetailsFooterHelper><BiPrinter size={16} /></ModalMobile.InfoDetailsFooterHelper></ModalMobile.InfoDetailsFooterHelpersContainer>
              </ModalMobile.InfoDetailsFooterContainer>
            </ModalMobile.Container>
          </Modal>
        </Step0.Container>
      )}
      {transferStep === 1 && (
        <Step1.Container id="printAreaBRA">
          <Step1.BankContainer>
            <Step1.Title>
              Banco selecionado:
            </Step1.Title>
            <Step1.SelectedBankContainer>
              <BankCardComponent  db={db} bank={selectedBank} active={true} />
              <Step1.ChangeBankButton
                onClick={() => {
                  setSelectedBank('')
                  setTransferStep(0)
                }}
              >
                Editar banco
              </Step1.ChangeBankButton>
            </Step1.SelectedBankContainer>
            <Step1.DataContainer>
              <Step1.DataTitleContainer>
                <Step1.Title style={{ alignSelf: 'center' }}>
                  Datos do pagador:
                </Step1.Title>
                { !Boolean(payerData) &&
                  <Step1.ChangeDataButton
                    editing={editing}
                    onClick={!editing ? () => setEditing(true) : handleSubmit(stepTwoSubmit)}
                  >
                    {!editing ? 'Editar dados' : 'Salvar'}
                  </Step1.ChangeDataButton>

                }
              </Step1.DataTitleContainer>
              {!editing && (
                <Step1.DataListContainer>
                  <Step1.DataListItemContainer>
                    <Step1.DataListItemTitle>CPF/CNPJ</Step1.DataListItemTitle>
                    <Step1.DataListItem>{data.DocNumber}</Step1.DataListItem>
                  </Step1.DataListItemContainer>
                  <Step1.DataListItemContainer>
                    <Step1.DataListItemTitle>E-mail</Step1.DataListItemTitle>
                    <Step1.DataListItem>{data.Email}</Step1.DataListItem>
                  </Step1.DataListItemContainer>
                  <Step1.DataListItemContainer>
                    <Step1.DataListItemTitle>Nome</Step1.DataListItemTitle>
                    <Step1.DataListItem>{data.FirstName}</Step1.DataListItem>
                  </Step1.DataListItemContainer>
                  <Step1.DataListItemContainer>
                    <Step1.DataListItemTitle>Sobrenome</Step1.DataListItemTitle>
                    <Step1.DataListItem>{data.LastName}</Step1.DataListItem>
                  </Step1.DataListItemContainer>
                  <Step1.DataListItemContainer>
                    <Step1.DataListItemTitle>Celular</Step1.DataListItemTitle>
                    <Step1.DataListItem>{data.Mobile}</Step1.DataListItem>
                  </Step1.DataListItemContainer>
                  {selectedBank === 'ITAÚ' && (
                    <Step1.DataListItemContainer style={{gridColumn: 'span 2 / span 2'}}>
                      <Step1.DataListItemTitle>Tipo de conta</Step1.DataListItemTitle>
                      <Step1.DataListItem>{accountNormalize(data.accountType)}</Step1.DataListItem>
                    </Step1.DataListItemContainer>
                  )}
                  {extendedFieldBankList.includes(selectedBank) && (
                    <>
                      <Step1.DataListItemContainer>
                        <Step1.DataListItemTitle>Agência</Step1.DataListItemTitle>
                        <Step1.DataListItem>{data.branch}</Step1.DataListItem>
                      </Step1.DataListItemContainer>
                      <Step1.DataListItemContainer>
                        <Step1.DataListItemTitle>Número da Conta com Dígito</Step1.DataListItemTitle>
                        <Step1.DataListItem>{data.account}</Step1.DataListItem>
                      </Step1.DataListItemContainer>
                    </>
                  )}
                </Step1.DataListContainer>
              )}
              {editing && (
                <TransferForm
                  register={register}
                  handleSubmit={() => {}}
                  errors={errors}
                  isValid={isValid}
                  control={control}
                  setValue={setValue}

                  onSubmit={(d) => console.log(d)}
                  selectedBank={selectedBank}
                  payerData={false}
                  data={data}
                  showSubmit={false}
                />
              )}
            </Step1.DataContainer>
          </Step1.BankContainer>
          <Step1.InfoContainer>
            <Step1.InfoTitleContainer>
              <Step1.InfoTitlePre>
                Você tem até
              </Step1.InfoTitlePre>
              <Step1.InfoTitleTextContainer>
                <Step1.InfoTitle1>
                  {dayjs(db.data.dataOperation.TxExpiration).format('DD/MM/YYYY')}
                </Step1.InfoTitle1>
                <Step1.InfoTitle2>
                  {dayjs(db.data.dataOperation.TxExpiration).format('HH:mm') + ' Hrs'}
                </Step1.InfoTitle2>
              </Step1.InfoTitleTextContainer>
              <Step1.InfoTitleSub>
                para realizar o seu pagamento
              </Step1.InfoTitleSub>
            </Step1.InfoTitleContainer>
            <Step1.InfoDetailsContainer>
              <Step1.InfoAccountDetailsContainer>
                <Step1.InfoDetailsLabelContainer border={true}>
                  <Step1.InfoDetailsLabel>
                    Agência
                  </Step1.InfoDetailsLabel>
                </Step1.InfoDetailsLabelContainer>
                <Step1.InfoDetailsTextContainer border={true}>
                  <Step1.InfoDetailsText>
                    {getNumberAccount(selectedBankData.ShortName).Ag}
                  </Step1.InfoDetailsText>
                </Step1.InfoDetailsTextContainer>
                <Step1.InfoDetailsLabelContainer border={true}>
                  <Step1.InfoDetailsLabel>
                    Conta corrente
                  </Step1.InfoDetailsLabel>
                </Step1.InfoDetailsLabelContainer>
                <Step1.InfoDetailsTextContainer border={true}>
                  <Step1.InfoDetailsText>
                    {getNumberAccount(selectedBankData.ShortName).Cuenta}
                  </Step1.InfoDetailsText>
                </Step1.InfoDetailsTextContainer>
                <Step1.InfoDetailsLabelContainer border={true}>
                  <Step1.InfoDetailsLabel>
                    Valor Total
                  </Step1.InfoDetailsLabel>
                </Step1.InfoDetailsLabelContainer>
                <Step1.InfoDetailsTextContainer border={true}>
                  <Step1.InfoDetailsText>
                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(db.data.dataOperation.Amount))}
                  </Step1.InfoDetailsText>
                </Step1.InfoDetailsTextContainer>
                <Step1.InfoDetailsLabelContainer>
                  <Step1.InfoDetailsLabel>
                    Número do pedido
                  </Step1.InfoDetailsLabel>
                </Step1.InfoDetailsLabelContainer>
                <Step1.InfoDetailsTextContainer>
                  <Step1.InfoDetailsText>
                    {db.data.dataOperation.PaymentCode}
                  </Step1.InfoDetailsText>
                </Step1.InfoDetailsTextContainer>
              </Step1.InfoAccountDetailsContainer>
              <Step1.InfoDetailsInstructionsTitle>Faça a transferência seguindo o passo a passo a seguir:</Step1.InfoDetailsInstructionsTitle>
              {selectedBankData.Instructions.map((instruction) => (
                <Step1.InfoDetailsInstructionsContainer key={instruction.step}>
                  {instruction.icon
                      ? <img src={instruction.icon} style={{ alignSelf: 'flex-start', width: 16 }} alt={`Instrução ${instruction.step}`} />
                      : <span>&#9900;</span>
                  }
                  <Step1.InfoDetailsInstructionsText>{ReactHtmlParser(instruction.text)}</Step1.InfoDetailsInstructionsText>
                </Step1.InfoDetailsInstructionsContainer>
              ))}
            </Step1.InfoDetailsContainer>
            <Step1.InfoDetailsFooterContainer>
              <Step1.InfoDetailsFooterButtonContainer><Step1.InfoDetailsFooterButton onClick={() => window.open(selectedBankData.UrlBank, '_blank').focus()}>Ir a banco</Step1.InfoDetailsFooterButton></Step1.InfoDetailsFooterButtonContainer>
              <Step1.InfoDetailsFooterHelpersContainer><Step1.InfoDetailsFooterHelper onClick={() => sendSMS()}><BsChatSquareDots size={16} /></Step1.InfoDetailsFooterHelper><Step1.InfoDetailsFooterHelper onClick={() => sendEmail()}><GoMail size={16} /></Step1.InfoDetailsFooterHelper><Step1.InfoDetailsFooterHelper onClick={() => printDiv()}><BiPrinter size={16} /></Step1.InfoDetailsFooterHelper></Step1.InfoDetailsFooterHelpersContainer>
            </Step1.InfoDetailsFooterContainer>
          </Step1.InfoContainer>
        </Step1.Container>
      )}
      <ModalResponse modalOpen={isOpenModal} setModalOpen={setIsOpenModal}>
        <p>{modalMessage}</p>
      </ModalResponse>
    </>
  )
}

export default TransferView

const Step0 = {
  Container: styled.div`
    max-width: 32rem;
    display: flex;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    @media only screen
    and (max-width : 700px) {
      margin-right: .5rem;
      margin-left: .5rem;
    }
  `,
  Title: styled.span`
    color: ${({theme}) => theme.colors['wp-blue']};
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
  `,
  BanksContainer: styled.div`
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    @media only screen
    and (max-width : 700px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    gap: 1.25rem;
  `,
  Hint: styled.p`
    font-size: 0.875rem;
    color: ${({theme}) => theme.colors['wp-gray-3']};
    font-style: italic;
    line-height: 1rem;
    margin-bottom: 1.25rem;
    & > span {
      color: ${({theme}) => theme.colors['wp-blue']};
      cursor: pointer;
    }
  `,
  HR: styled.hr`
    background-color: ${({theme}) => theme.colors['wp-gray-4']};
    border: 0;
    margin-bottom: 1.5rem;
    height: 1px;
  `,
  PayerDataContainer: styled.div`
    display: flex;
    align-items: center;
    @media only screen
    and (max-width : 700px) {
      align-items: flex-start;
      flex-direction: column;
    }
    justify-content: space-between;
    margin-bottom: 1.5rem;
  `,
  PayerDataText: styled.span`
    line-height: 1rem;
    color: ${({theme}) => theme.colors['wp-griss']};
  `
}
const Step1 = {
  Container: styled.div`
    display: flex;
    max-width: 962px;
    @page { size: landscape; }
  `,
  BankContainer: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-right: 2rem;
    padding-bottom: 2.25rem;
    padding-top: 1.75rem;
    border-color: ${({theme}) => theme.colors['wp-gray-4']};
    border-style: solid;
    border-width: 0;
    border-right-width: 1px;
    @media only screen
    and (max-width : 700px) {
      display: none;
    }
  `,
  Title: styled.span`
    color: ${({theme}) => theme.colors['wp-blue']};
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-right: 1rem;
  `,
  SelectedBankContainer: styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
  `,
  ChangeBankButton: styled.div`
    display: flex;
    height: 2.5rem;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    border-width: 1px;
    border-style: solid;
    border-color: ${({theme}) => theme.colors['wp-blue']};
    color: ${({theme}) => theme.colors['wp-blue']};
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding-left: 0.875rem;
    padding-right: 0.875rem;
    @media print {
      display: none;
    }
  `,
  HR: styled.hr`
    background-color: ${({theme}) => theme.colors['wp-gray-4']};
    border: 0;
    margin-bottom: 1.75rem;
    margin-top: 2.5rem;
    height: 1px;
  `,
  DataContainer: styled.div`
    margin-top: 2.5rem;
    padding-top: 1.7rem;
    display: flex;
    border-style: solid;
    border-width: 0;
    border-top-width: 1px;
    flex-direction: column;
  `,
  DataTitleContainer: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  ChangeDataButton: styled.div`
    display: flex;
    height: 2.5rem;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    border-width: 1px;
    border-style: solid;
    border-color: ${({theme, editing}) => editing ? theme.colors['wp-green'] : theme.colors['wp-blue']};
    color: ${({theme, editing}) => editing ? theme.colors['wp-green'] : theme.colors['wp-blue']};
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  `,
  DataListContainer: styled.div`
    margin-top: 2.75rem;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  `,
  DataListItemContainer: styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    color: ${({theme}) => theme.colors['wp-gray-5']}
  `,
  DataListItemTitle: styled.span`
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
  `,
  DataListItem: styled.span`
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-top: .2rem;
  `,
  InfoContainer: styled.div`
    margin-left: 2.125rem;
    display: flex;
    flex-direction: column;
  `,
  InfoTitleContainer: styled.div`
    background-color: ${({ theme }) => theme.colors['wp-blue-2']};
    padding: 1.75rem 6.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `,
  InfoTitlePre: styled.span`
    color: ${({theme}) => theme.colors['wp-white']};
    font-size: 1rem;
    font-weight: 500;
  `,
  InfoTitleTextContainer: styled.div`
    display: flex;
  `,
  InfoTitle1: styled.span`
    color: ${({theme}) => theme.colors['wp-white']};
    font-size: 1.5rem;
    font-weight: 500;
    margin-right: 1rem;
  `,
  InfoTitle2: styled.span`
    color: ${({theme}) => theme.colors['wp-white']};
    font-size: 1.25rem;
    font-weight: 500;
    align-self: flex-end;
  `,
  InfoTitleSub: styled.span`
    color: ${({theme}) => theme.colors['wp-white']};
    font-size: 0.75rem;
    font-weight: 500;
  `,
  InfoDetailsContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors['wp-gray-9']};
    padding: 0.625rem 2.25rem 2.375rem;
  `,

  InfoAccountDetailsContainer: styled.div`
    display: grid;
    grid-template-columns: repeat(2,minmax(0,1fr));
    width: 100%;
  `,
  InfoDetailsLabelContainer: styled.div`
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    border-color: ${({theme}) => theme.colors['wp-gray-10']};
    padding-right: 1.5rem;
    ${({border}) => border ? `
      border-width: 0;
      border-bottom-width: 1px;
      border-style: solid;
    ` : ''}
  `,
  InfoDetailsTextContainer: styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-color: ${({theme}) => theme.colors['wp-gray-10']};
    padding-left: 1.5rem;
    ${({border}) => border ? `
      border-width: 0;
      border-bottom-width: 1px;
      border-style: solid;
    ` : ''}
  `,
  InfoDetailsLabel: styled.span`
    font-size: 0.875rem;
    color: ${({theme}) => theme.colors['wp-blue']};
    font-weight: 500;
  `,
  InfoDetailsText: styled.span`
    font-weight: 500;
    color: ${({theme}) => theme.colors['wp-gris']};
    font-size: 1rem;
  `,

  InfoDetailsInstructionsTitle: styled.span`
    padding-top: 1.65rem;
    padding-bottom: 1.625rem;
    align-self: flex-start;
    color: ${({theme}) => theme.colors['wp-gray-2']};
    font-size: 0.75rem;
    font-weight: 500;
  `,
  InfoDetailsInstructionsContainer: styled.div`
    padding-bottom: 0.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
  `,
  InfoDetailsInstructionsText: styled.span`
    //line-height: 1rem; 
    padding-left: 1rem;
    color: ${({theme}) => theme.colors['wp-gris']};
    font-size: 0.625rem;
  `,

  InfoDetailsFooterContainer: styled.div`
    margin-top: 1.375rem;
    display: grid;
    grid-template-columns: repeat(3,minmax(0,1fr));
    @media print {
      display: none;
    }
  `,
  InfoDetailsFooterButtonContainer: styled.div`
    grid-column-start: 2;
    justify-self: center;
  `,
  InfoDetailsFooterButton: styled.div`
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors['wp-blue']};
    color: ${({ theme }) => theme.colors['wp-white']};
    font-size: 0.875rem;
    font-weight: 500;
    padding: 0.625rem 1.375rem;
    border-radius: 4px;
  `,
  InfoDetailsFooterHelpersContainer: styled.div`
    justify-self: end;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  InfoDetailsFooterHelper: styled.div`
    cursor: pointer;
    color: ${({ theme }) => theme.colors['wp-blue']};
    border-color: ${({ theme }) => theme.colors['wp-blue']};
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;
    display: flex;
    justify-content: center;
    width: 28px;
    height: 28px;
    align-items: center;
    margin-right: 0.625rem;
  `
}

const ModalMobile = {
  Container: styled.div`
    padding: 1rem;
  `,

  HeaderContainer: styled.div`
    background-color: ${({theme}) => theme.colors['wp-blue-2']};
    border-radius: 1rem 1rem 0 0;
    display: grid;
    grid-template-columns: repeat(20, minmax(0, 1fr));
    padding: 1.25rem;
    color: ${({theme}) => theme.colors['wp-white']};
  `,
  HeaderTextContainer: styled.div`
    grid-column-start: 5;
    grid-column-end: 19;
    display: grid;
    row-gap: .5rem;
    grid-template-columns: repeat(20, minmax(0, 1fr));
  `,
  HeaderTextContainerSubtitle: styled.div`
    align-self: center;
    grid-column-start: 6;
    grid-column-end: 20;
    grid-row-start: 1;
    grid-row-end: 1;
    font-size: 1rem;
    font-weight: 600;
  `,
  HeaderTextContainerTitle: styled.div`
    grid-column-start: 1;
    grid-column-end: 20;
    grid-row-start: 2;
    grid-row-end: 2;
    font-size: 1.5rem;
    font-weight: 600;
  `,
  HeaderTextContainerTitleHint: styled.span`
    font-size: 1.25rem;
  `,
  HeaderTextContainerSubtitle2: styled.div`
    grid-column-start: 2;
    grid-column-end: 20;
    grid-row-start: 3;
    grid-row-end: 3;
    font-size: .875rem;
    font-weight: 600;
  `,
  HeaderCloseContainer: styled.div``,

  InfoAccountDetailsContainer: styled.div`
    display: grid;
    grid-template-columns: repeat(2,minmax(0,1fr));
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  `,
  InfoDetailsLabelContainer: styled.div`
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    border-color: ${({theme}) => theme.colors['wp-gray-10']};
    padding-right: 1.5rem;
    ${({border}) => border ? `
      border-width: 0;
      border-bottom-width: 1px;
      border-style: solid;
    ` : ''}
  `,
  InfoDetailsTextContainer: styled.div`
    padding-left: 1.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-color: ${({theme}) => theme.colors['wp-gray-10']};
    ${({border}) => border ? `
      border-width: 0;
      border-bottom-width: 1px;
      border-style: solid;
    ` : ''}
  `,
  InfoDetailsLabel: styled.span`
    font-size: 0.875rem;
    color: ${({theme}) => theme.colors['wp-blue']};
    font-weight: 600;
  `,
  InfoDetailsText: styled.span`
    font-weight: 600;
    color: ${({theme}) => theme.colors['wp-gris']};
    font-size: 1rem;
  `,


  InfoDetailsInstructionsTitleContainer: styled.div`
    margin-top: 0.75rem;
    margin-bottom: 1.375rem;
  `,
  InfoDetailsInstructionsTitle: styled.span`
    padding: 1.65rem 0.5rem 1.625rem 0.5rem;
    align-self: flex-start;
    color: ${({ theme }) => theme.colors['wp-gray-2']};
    font-size: 0.75rem;
    font-weight: 600;
  `,
  InfoDetailsInstructionsContainer: styled.div`
    padding-left: 0.5rem;
    padding-bottom: 0.625rem;
    display: flex;
    align-items: center;
    align-self: flex-start;
  `,
  InfoDetailsInstructionsText: styled.span`
    line-height: 1rem;
    padding-left: 1rem;
    color: ${({theme}) => theme.colors['wp-gris']};
    font-size: 0.75rem;
  `,

  InfoDetailsFooterContainer: styled.div`
    margin-top: 1.375rem;
    display: grid;
    grid-template-columns: repeat(2,minmax(0,1fr));
  `,
  InfoDetailsFooterButtonContainer: styled.div`
    justify-self: center;
  `,
  InfoDetailsFooterButton: styled.div`
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors['wp-blue']};
    color: ${({ theme }) => theme.colors['wp-white']};
    font-size: 0.875rem;
    font-weight: 500;
    padding: 0.625rem 1.375rem;
    border-radius: 4px;
  `,
  InfoDetailsFooterHelpersContainer: styled.div`
    justify-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  InfoDetailsFooterHelper: styled.div`
    cursor: pointer;
    color: ${({ theme }) => theme.colors['wp-blue']};
    border-color: ${({ theme }) => theme.colors['wp-blue']};
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;
    display: flex;
    justify-content: center;
    width: 28px;
    height: 28px;
    align-items: center;
    margin-right: 0.625rem;
  `
}
